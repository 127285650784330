(function () {
    angular.module('informaApp')
        .service('BarChartMapper', BarChartMapper);

    function BarChartMapper() {
        return {
            map: (source) => map(source),
            mapAll: (source) => mapAll(source)
        }
    }

    function mapAll(source, DrugIndicationMapper) {
        return source.map(x => map(x, DrugIndicationMapper));
    }

    function map(source) {
        return {
            label: source.name,
            PTS: getValuesFromProcessedLine(source.pos),
            LOA: getValuesFromProcessedLine(source.loa),
            Duration: getValuesFromProcessedLine(source.durations),
            MedianDuration: getValuesFromProcessedLine(source.medianDurations),
            BoxPlotData: source.boxPlotData,
            NumberOfPrograms: getValuesFromProcessedLine(source.transitions).map(_.sum),
            uniqueDrugIndicationQuantity: source.uniqueDrugIndicationQuantity,
            pinned: source.pinned
        }
    }

    function getValuesFromProcessedLine({source}) {
        return source.map(({data}) => {
            return data.length === 1
                ? data[0].value
                : data.map(x => x.value);
        });
    }
})();